import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";

import { Pill, PopOver, PopOverMenu, Text } from "components/commons";

import { AccountStatus, DateTime } from "enums";
import locale from "localization";
import { prettifyAccountStatus, formatNumber } from "utils";

import styles from "./card-list.module.scss";

export const mapDataToList = ({ loyaltyCards, history, handleUpdateStatus, getUserAccess }) => {
  const {
    loyaltyCardId,
    cardNumber,
    remarks,
    createdAt,
    status,
    updatedAt,
    points,
    registeredAt,
    caravan,
  } = loyaltyCards;

  const accountDate = {
    registered: moment(updatedAt || createdAt).format(DateTime.Q),
    deactivated: moment(updatedAt || createdAt).format(DateTime.Q),
    expired: moment(updatedAt || createdAt).format(DateTime.Q),
    unregistered: moment(updatedAt || createdAt).format(DateTime.Q),
    pending: "--",
  };

  let options = [];

  if (getUserAccess?.fullAccess || getUserAccess?.modifyAccess) {
    const createButton = (content, newStatus) => ({
      removable: true,
      content: content,
      onClick: () => {
        handleUpdateStatus({
          cardNumber,
          status: newStatus,
        });
      },
    });

    const deactivateButton = createButton(locale.deactivate, AccountStatus.Deactivated);
    const reactivateButton = createButton(locale.reactivate, AccountStatus.Registered);

    const modifyDetails = [
      (status === AccountStatus.Registered || status === AccountStatus.UnRegistered) &&
        deactivateButton,
      status === AccountStatus.Deactivated && reactivateButton,
    ].filter(Boolean);

    options.push(...modifyDetails);
  }

  return {
    loyalCardID: (
      <>
        <Text>{loyaltyCardId}</Text>
      </>
    ),
    vipCardNumber: (
      <>
        <Text>{cardNumber}</Text>
      </>
    ),
    vipPoints: <Text>{formatNumber(points, 2)}</Text>,
    assignedStationOrCaravan: caravan ? caravan : "--",
    dateProcured: createdAt ? moment(createdAt).format(DateTime.Q) : "--",
    dateRegistered: registeredAt ? moment(registeredAt).format(DateTime.Q) : "--",
    statusDate: (
      <>
        <Pill
          grass={status === AccountStatus.Registered}
          sky={status === AccountStatus.UnRegistered}
          deepRed={status === AccountStatus.Expired}
          cement={status === AccountStatus.Deactivated}
        >
          {prettifyAccountStatus(status)}
        </Pill>
        <Text className={styles.subBusinessId}>{accountDate[`${status}`]}</Text>
      </>
    ),
    remarks: remarks ? (
      <PopOver content={<div className={styles.remarks}>{remarks}</div>}>
        <div className="link">View</div>
      </PopOver>
    ) : (
      ""
    ),
    actions:
      status !== AccountStatus.Expired ? (
        <PopOverMenu options={options}>
          <MoreVertIcon className={styles.icon} />
        </PopOverMenu>
      ) : (
        ""
      ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { loyaltyCardStatuses, accountType, ...fs } = filterState;
  const newStatus = loyaltyCardStatuses !== "all" ? loyaltyCardStatuses : "";

  return {
    ...fs,
    loyaltyCardStatuses: newStatus,
    // stationIds: stationIds.value,
  };
};
