import { Product, Pill, PopOver, PopOverMenu, Text } from "components/commons";
import { DateTime, Products, SettlementStatus } from "enums";
import RedemptionStatus from "enums/redemption-status";
import { formatVolume, formatAmount } from "utils";
import {
  prettifyProduct,
  prettifyRedemptionStatus,
  prettifySettlementStatus,
  prettifyVoidTransactionValue,
  prettifyDispenseType,
} from "utils/pretty.utils";
import styles from "./redemptions.module.scss";
import locale from "localization";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";

export const mapDataToList = (
  redemptions,
  setRemarks,
  voidTransactionModal,
  onVoidTransactionCb,
  updateRemarksApi,
  setValue
) => {
  const {
    redemptionId,
    fleet,
    station,
    driver,
    dispensedTo,
    vehicle,
    fuelCode,
    redeemedAt,
    productCode,
    literVolume,
    averageCreditPrice,
    totalCreditPrice,
    pointsEarned,
    pumpPrice,
    orNumber,
    status,
    settlementStatus,
    remarks,
    settlementId,
  } = redemptions;

  const options = [];
  if (status === RedemptionStatus.Success) {
    options.push({
      content: locale.voidTransaction,
      onClick: () => {
        setRemarks("");
        voidTransactionModal.show({
          redemptions,
          title: `${locale.voidTransaction}?`,
          description: (
            <Text align="center">
              <locale.Populate
                text={
                  settlementId
                    ? settlementStatus === SettlementStatus.ForProcessing
                      ? locale.voidingThisTransactionSettlementProcessing
                      : locale.voidingThisTransactionSettlementProcessed
                    : locale.voidingThisTransaction
                }
                items={[<b>{formatVolume(literVolume)}</b>]}
              />
            </Text>
          ),
          label: locale.reasonRemarks,
          actionText: locale.voidTransaction,
          submit: (value) => {
            onVoidTransactionCb({
              redemptionItem: redemptions,
              value,
            });
          },
        });
      },
    });
  }

  if (status === RedemptionStatus.Voided) {
    options.push({
      content: locale.editRemarks,
      onClick: () => {
        setRemarks(remarks);
        setValue(prettifyVoidTransactionValue(remarks));
        voidTransactionModal.show({
          redemptions,
          title: locale.editRemarks,
          description: null,
          label: locale.reasonRemarks,
          actionText: locale.save,
          submit: async (value) => {
            await updateRemarksApi.request({
              id: redemptionId,
              remarks: value,
            });
            redemptions.remarks = value;
            voidTransactionModal.close();
            setRemarks("");
          },
        });
      },
    });
  }

  const obj = {
    redemptionItemIdAndId: <div className="min-70">{redemptionId}</div>,
    businessNameId: (
      <div>
        <Text>{fleet.businessName}</Text>
        <Text className={styles.subBusinessId}>{fleet?.shortName}</Text>
      </div>
    ),
    redemptionStationAndStationBusinessName: (
      <div>
        <Text>{station?.name}</Text>
        <Text className={styles.subBusinessId}>{station?.businessName}</Text>
      </div>
    ),
    driversNameandId: (
      <div>
        {driver ? (
          <Text>{`${driver?.firstName} ${driver?.lastName}`} </Text>
        ) : (
          <Text>
            <i>{locale.noDriver}</i>
          </Text>
        )}

        <Text className={styles.subBusinessId}>{driver?.driverLicenseId}</Text>
      </div>
    ),
    dispensedToPlateNumber: (
      <div>
        <Text>{prettifyDispenseType(dispensedTo)}</Text>
        <Text className={styles.subBusinessId}>{vehicle ? vehicle.plateNumber : locale.any}</Text>
      </div>
    ),
    fuelCodeAndDate: (
      <div>
        <Text>{fuelCode}</Text>
        <Text className={styles.subBusinessId}>{moment(redeemedAt).format(DateTime.H)}</Text>
      </div>
    ),
    product: (
      <Product
        grass={productCode === Products.Gas91}
        salsa={productCode === Products.Gas95}
        deepBlue={productCode === Products.Gas97}
        cheddar={productCode === Products.Diesel}
      >
        {prettifyProduct(productCode)}
      </Product>
    ),
    volumeCreditPrice: (
      <div>
        <Text>{formatVolume(literVolume)}</Text>
        <Text className={styles.subBusinessId}>{formatAmount(averageCreditPrice)}</Text>
      </div>
    ),
    totalCreditAmount: <div className="min-100">{formatAmount(totalCreditPrice)}</div>,
    pointsEarned: <div className="min-100">{pointsEarned}</div>,
    pumpPrice: <div className="min-100">{formatAmount(pumpPrice)}</div>,
    orNumber: orNumber,
    status: (
      <Pill grass={status === RedemptionStatus.Success} cement={status === RedemptionStatus.Voided}>
        {prettifyRedemptionStatus(status)}
      </Pill>
    ),
    settlementStatus: (
      <>
        <Pill
          grass={settlementStatus === SettlementStatus.Processed}
          cyan={settlementStatus === SettlementStatus.ForSettlement}
          sky={settlementStatus === SettlementStatus.ForProcessing}
        >
          {prettifySettlementStatus(settlementStatus)}
        </Pill>
        {status !== RedemptionStatus.Voided &&
        (settlementStatus === SettlementStatus.ForProcessing ||
          settlementStatus === SettlementStatus.Processed) ? (
          <Text className={styles.subBusinessId}>{settlementId}</Text>
        ) : (
          <Text className={styles.subBusinessId}>--</Text>
        )}
      </>
    ),
    remarks: remarks ? (
      <PopOver content={<div className={styles.remarks}>{remarks}</div>}>
        <div className="link">View</div>
      </PopOver>
    ) : (
      ""
    ),
    options: options.length > 0 && (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };

  return obj;
};

export const mapFilterToRequest = (filterState) => {
  const {
    redemptionStation,
    redemptionStatuses,
    productCodes,
    dispensedTo,
    settlementStatuses,
    ...props
  } = filterState;
  if (redemptionStation.value.length) {
    props.redemptionStationIds = redemptionStation.value
      .map(({ value }) => {
        return value;
      })
      .join(",");
  }

  const newDispenseTo = dispensedTo !== "all" ? dispensedTo : "";

  return {
    ...props,
    redemptionStatuses: !redemptionStatuses?.isSelectedAll
      ? redemptionStatuses.value.join(",")
      : null,
    productCodes: productCodes.length < 4 ? productCodes.join(",") : null,
    settlementStatuses: !settlementStatuses?.isSelectedAll
      ? settlementStatuses.value.join(",")
      : null,
    dispensedTo: newDispenseTo,
  };
};
