const ApiPath = {
  Login: "ms-profile/user/login",
  Logout: "ms-profile/user/logout",
  Refresh: "ms-profile/user/refresh",
  GetUser: "ms-profile/user/v2",
  GetUsers: "ms-profile/user/users",
  UserInvite: "ms-profile/user/invite",
  GetUserProfile: "ms-profile/user",
  UpdateUserAccess: "ms-profile/user/user-access",
  GetDepots: "ms-pricing/depot",
  GetBooking: "ms-booking/booking",
  GetWholesalePrice: "ms-pricing/supplier-wpp",
  UploadWholesalePrice: "ms-pricing/supplier-wpp/batch",
  UniqueVoucherClaims: "ms-fleet/voucher-campaign/claims",
  VoucherCampaigns: "ms-fleet/voucher-campaign",
  GenerateReportAdmin: "ms-fleet/report/admin/v3",
  GenerateReportAdminLoyalty: "ms-loyalty/report/admin",
  SettlementHistory: "ms-pricing/settlement/oilco",
  SettlementRedemption: "ms-pricing/redemption/settlement",
  Inbox: "ms-inbox/message/user",
  GeneratePricingReport: "ms-pricing/report",
  GeneratePricingReportv2: "ms-pricing/report/v2",
  Fleet: "ms-fleet/fleet",
  GetUserFleets: "ms-fleet/user/fleet",
  GetFleetPriceTool: "ms-fleet/fleet/price-tool",
  PricingDetails: (fleetId) => `ms-pricing/virtual-station/fleet/${fleetId}`,
  PricingMovement: `ms-pricing/price-movement`,
  Station: "ms-fleet/station",
  StationPricing: "ms-pricing/station",
  StationAccounts: "ms-fleet/station/account",
  GetPriceBuildup: "ms-fleet/station/price-build-up",
  GetSeaoilPriceTool: "ms-pricing/station/seaoil/price-tool",
  GetStationPricing: (stationId) => `ms-pricing/station/${stationId}/pricing-data`,
  GetPriceChanges: "ms-fleet/fleet/audit-trail",
  VirtualStation: "ms-fleet/virtual-station",
  PricingVirtualStation: "ms-pricing/virtual-station",
  UpdateReport: (id) => `ms-report/report/${id}`,
  GenerateReportList: "ms-report/report",
  PremiumPayout: "ms-pricing/premium-payout",
  GetStationNegotiatedDiscount: "ms-pricing/station/station-negotiated-discount",
  CampaignVouchers: "ms-fleet/voucher-campaign",
  UserAttributes: "ms-fleet/user/attributes",
  VoucherCampaignCreators: "ms-fleet/voucher-campaign/creators",
  GenerateBookingReport: "ms-booking/report",
  GetCashIns: "ms-fleet/cashin",
  ManualCashIn: "ms-fleet/cashin/manual",
  Payments: "ms-fleet/payment",
  FleetRedemption: "ms-fleet/redemption",
  RedemptionNew: "ms-fleet/redemption/platform-type",
  Redemption: "ms-fleet/redemption/redemption-items",
  FuelBalance: "ms-fleet/fuel-balance/fleet",
  GetFuelBalanceUser: "ms-fleet/fuel-balance/user",
  GetUserByMobileNumber: "ms-profile/user/mobile-number",
  ValidateVoucherRecipient: "ms-fleet/voucher-campaign/recipients",
  Customers: "ms-fleet/user/user-details",
  UpdateCustomer: (userId) => `ms-profile/user/${userId}/customer`,
  AccountTypesList: "ms-profile/account-type",
  UserAttribute: "ms-fleet/user/attribute",
  UserFleet: "ms-fleet/user",
  UpdateMobileNumber: `ms-profile/user/update`,
  VIPCard: "ms-loyalty/loyalty/loyalty-card",
};

export default ApiPath;
