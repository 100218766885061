import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./pop-over-menu.module.scss";
import PopOver from "../pop-over/pop-over";

const PopOverMenu = ({ options = [], children, position, disabledClick = false, style }) => {
  const handleClose = (option = null) => {
    if (option?.onClick) {
      option.onClick();
    }
  };

  return (
    <PopOver
      contentStyle={styles.content}
      backgroundStyle={styles.background}
      className={styles.container}
      arrowStyle={styles.arrow}
      closeOnClick
      style={style}
      content={
        <div
          style={{
            with: "100%",
            overflow: "auto",
          }}
        >
          <div
            onClose={handleClose}
            style={{
              maxHeight: "190px",
            }}
          >
            {options.map((option, i) => {
              if (option.removable && option.disabled) {
                return "";
              } else {
                return (
                  <MenuItem
                    key={i}
                    onClick={() => handleClose(option)}
                    className={styles.item}
                    disabled={option.disabled}
                  >
                    <span
                      style={{
                        wordBreak: "break-word",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      {option.content}
                    </span>
                  </MenuItem>
                );
              }
            })}
          </div>
        </div>
      }
    >
      {children}
    </PopOver>
  );
};

export default PopOverMenu;
