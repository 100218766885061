import React, { useState, useCallback } from "react";
import { useForm, useApi } from "hooks";
import { initialState } from "./login.state";
import styles from "./login.module.scss";
import { login, getUser } from "apis";
import { redirectTo } from "utils";
import { ErrorCode, Path, UserGroup, Portal, Role } from "enums";
import { isEmailValid } from "utils/text.utils";

import {
  Checkbox,
  Field,
  TextField,
  PasswordField,
  Button,
  Highlight,
  Text,
  Image,
  Title,
  Footer,
} from "components/commons";
import classNames from "classnames";
import { SeaoilLogo } from "images";
import locale from "localization";
import { handleRequest } from "utils";
import { YellowGasTanker } from "images";
import * as queryString from "query-string";

const LoginModule = (props) => {
  const [incorrectCreds, setIncorrectCreds] = useState();
  const parse = queryString.parse(props.location ? props.location.search : null);
  const [errorCode] = useState(parse.code || null);

  const { request: loginRequest, loading: loggingIn } = useApi({
    api: login,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { fields, modifyField, submitForm, makeFormInvalid, getFormValues, applyFieldErrors } =
    useForm({
      initialState,
    });

  const { request: getUserRequest, loading: fetchingUser } = useApi({
    api: getUser,
    pageError: true,
  });

  const fetchUser = useCallback(async () => {
    return await getUserRequest();
  }, [getUserRequest]);

  const handleLogin = async () => {
    setIncorrectCreds(false);
    const { email, password, remember } = getFormValues();
    if (!email || !password) {
      applyFieldErrors({
        email: !email ? "" : null,
        password: !password ? "" : null,
      });
      return;
    }

    handleRequest(
      async () => {
        if (!isEmailValid(email)) {
          applyFieldErrors({
            email: locale.pleaseEnterAValidEmailAddress,
          });
          return;
        }
        const res = await loginRequest({ email, password }, () => handleLogin());
        localStorage.setItem("accessToken", res?.AccessToken);
        localStorage.setItem("refreshToken", res.RefreshToken);

        if (res) {
          const userInfo = await fetchUser();

          // user must be admin and seaoil
          if (
            userInfo &&
            userInfo.groups.find(
              (userGroup) =>
                userGroup === UserGroup.LocqAdmin ||
                userGroup === UserGroup.Seaoil ||
                userGroup === UserGroup.SeaoilRetailPricing
            )
          ) {
            const { role } = userInfo?.userAccesses.find((element) => {
              return element.portal === Portal.OILCO;
            });
            const userLandingPage = [
              { role: Role.PortalAdmin, page: Path.Slash },
              { role: Role.LubeServ, page: Path.LubeServOnWheelsBookings },
              { role: Role.Marketing, page: Path.Station },
              { role: Role.Operations, page: Path.FleetAccounts },
              { role: Role.RetailPricing, page: Path.FleetAccounts },
              { role: Role.Accounting, page: Path.SettlementHistory },
              { role: Role.Finance, page: Path.VoucherCampaign },
              { role: Role.Audit, page: Path.FleetPrices },
              { role: Role.GEAD, page: Path.Slash },
            ];
            const userPage = userLandingPage.filter((item) => item.role === role);

            if (remember) {
              localStorage.setItem("email", email);
            } else {
              localStorage.removeItem("email");
            }

            redirectTo(userPage[0].page);
          } else {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            makeFormInvalid();
            setIncorrectCreds(true);
            return applyFieldErrors({
              email: "",
              password: "",
            });
          }
        } else {
          makeFormInvalid();
          setIncorrectCreds(true);
          return applyFieldErrors({
            email: "",
            password: "",
          });
        }
      },
      null,
      (err) => {
        if (err.data?.errorCode === ErrorCode.IncorrectCredentials) {
          makeFormInvalid();
          setIncorrectCreds(true);
          return applyFieldErrors({
            email: "",
            password: "",
          });
        }
        makeFormInvalid();
        setIncorrectCreds(true);
      }
    );
  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.header)}>
        <Image src={SeaoilLogo} className={styles.SeaoilLogoImage} />
      </div>

      <div className={styles.content}>
        <div className={styles.contentContainer}>
          <div className={styles.image}>
            <Image src={YellowGasTanker} className={styles.contentImage} />
          </div>

          <div className={styles.login}>
            <Title className={styles.title}>{locale.seaoilPortal}</Title>
            {!incorrectCreds &&
              errorCode &&
              (errorCode === ErrorCode.InvalidInviteUserToken ||
                errorCode === ErrorCode.ExpiredInviteUserToken) && (
                <Highlight warning>
                  <Text>{locale.expiredActivationLinkToken}</Text>
                </Highlight>
              )}
            {incorrectCreds && (
              <Highlight error>
                <Text>{locale.theEnteredEmailPassIncorrect}</Text>
              </Highlight>
            )}
            <Field {...fields.email}>
              <TextField {...fields.email} onChange={modifyField} />
            </Field>
            <Field {...fields.password}>
              <PasswordField
                {...fields.password}
                onChange={modifyField}
                onEnter={() => {
                  submitForm(handleLogin);
                }}
              />
            </Field>
            <div className={styles.remember}>
              <Checkbox {...fields.remember} onChange={modifyField} />
              {fields.remember.label}
            </div>
            <Button
              primary
              className={styles.loginButton}
              loading={loggingIn || fetchingUser}
              onClick={() => {
                submitForm(handleLogin);
              }}
            >
              {locale.logIn}
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginModule;
