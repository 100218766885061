import React, { useCallback } from "react";
import { Checkbox as MaterialCheckbox } from "@material-ui/core";
import styles from "./checkbox.module.scss";

const Checkbox = ({ value = false, onChange, name, disabled }) => {
  const onChangeCb = useCallback(() => {
    if (onChange) {
      onChange(name, {
        value: !value,
      });
    }
  }, [name, value, onChange]);

  return (
    <div>
      <MaterialCheckbox
        className={styles.checkbox}
        checked={value === "indeterminate" ? null : value}
        indeterminate={value === "indeterminate"}
        disabled={disabled}
        onChange={onChangeCb}
      />
    </div>
  );
};

export default Checkbox;
