import { Button, Intro } from "components/commons";
import React, { useContext, useMemo } from "react";
import locale from "localization";
import { generateReportAdmin, getRedemptions } from "apis";
import { initialFilterState } from "./redemption-filter.state";
import RedemptionFilter from "./redemption-filter";
import RedemptionList from "./redemption-list";
import useDataTable from "hooks/useDataTable";
import { columns } from "./redemption-columns";
import { mapDataToList, mapFilterToRequest } from "./redemption.mapper";
import { useApi, useModal, useRouter } from "hooks";
import { useState } from "react";
import { useCallback } from "react";
import { updateRedemptionRemarks, voidRedemption } from "apis/redemption.api";
import { formatVolume } from "utils";
import VoidTransactionModal from "./void-transaction-modal";
import { ConfirmModal, SuccessModal } from "components/modals";
import useExport from "hooks/useExport";
import { UserContext } from "contexts";
import { Path, UserAccess, Role } from "enums";

const RedemptionModule = () => {
  const { sessionId, userAccess } = useContext(UserContext);

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.PLCRedemption;
  });

  const { location, history } = useRouter();

  const voidTransactionModal = useModal();
  const confirmModal = useModal();

  const [remarks, setRemarks] = useState("");
  const [value, setValue] = useState("");

  const columnsList = useMemo(() => {
    return getUserAccess.role === Role.PortalAdmin
      ? columns
      : columns.filter((e) => e.key !== "options");
  }, [getUserAccess]);

  const { request: voidRequest, loading: voidingRequest } = useApi({
    api: voidRedemption,
    pageError: false,
    modalError: true,
  });

  const updateRemarksApi = useApi({
    api: updateRedemptionRemarks,
    modalError: true,
    pageError: false,
  });

  const onVoidTransactionCb = useCallback(
    async ({ redemptionItem, value }) => {
      await voidRequest({ redemptionId: redemptionItem.redemptionId, remarks: value });
      voidTransactionModal.close();
      // fetchRedemption();
      table.fetch({});

      const userDetail = redemptionItem?.userDetail;

      confirmModal.show({
        title: locale.transactionVoided,
        content: (
          <locale.Populate
            text={locale.thisTransactionHasBeenVoided}
            items={[
              <b>{formatVolume(redemptionItem?.literVolume)}</b>,
              <b>{`${userDetail.firstName} ${userDetail.lastName}.`}</b>,
            ]}
          />
        ),
        loading: voidingRequest,
        redemptionItem,
      });
      setRemarks("");
    },
    //eslint-disable-next-line
    [voidRequest, voidTransactionModal, voidingRequest, confirmModal]
  );

  const { filter, search, table } = useDataTable({
    api: { api: getRedemptions, params: { platformType: "plc" } },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "redemptions",
      mapper: (redemptions) =>
        mapDataToList(
          redemptions,
          setRemarks,
          voidTransactionModal,
          onVoidTransactionCb,
          updateRemarksApi,
          setValue,
          getUserAccess
        ),
      columns: columnsList,
    },
    searchKey: location?.state?.searchKey,
  });

  const exports = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "redemption-item",
    mappedFilterState: {
      searchKey: search.searchKey,
      platformType: "plc",
      connectionId: sessionId,
      ...filter.mappedFilterState,
    },
  });

  return (
    <div>
      <ConfirmModal {...confirmModal} />
      <SuccessModal {...exports?.successModalComponent} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Intro title={locale.redemption} subtitle={locale.viewTrackRedemptions} />
        {(getUserAccess?.fullAccess || getUserAccess?.modifyAccess) && (
          <Button primary onClick={() => history.push(Path.PLCManualRedemption)}>
            {locale.manualRedemption}
          </Button>
        )}
      </div>
      <RedemptionFilter {...filter} {...search} {...exports} getUserAccess={getUserAccess} />
      <RedemptionList table={table} />
      <VoidTransactionModal
        {...voidTransactionModal}
        remarks={remarks}
        onChangeRemarks={(v) => {
          setRemarks(v);
        }}
        loading={voidingRequest || updateRemarksApi.loading}
        onSubmit={voidTransactionModal.submit}
        value={value}
        setValue={setValue}
      />
    </div>
  );
};

export default RedemptionModule;
